// import { Navigate } from "react-router-dom";
// import { ROUTES } from "../lib/consts";
// import { useSelector } from "react-redux";

// const PrivateRoute = ({ children }: { children: JSX.Element }) => {
//   const accessDetails = useSelector((state: any) => state.authReducer);

//   // return !!accessDetails.accessToken ? children : <Navigate to={ROUTES.HOME} />;
//   return !!accessDetails.accessToken ? (
//     children
//   ) : (
//     <Navigate to={ROUTES.NOT_ALLOWED} />
//   );
// };

// export default PrivateRoute;

import { Navigate } from "react-router-dom";
import { ROUTES } from "../lib/consts";
import { useSelector } from "react-redux";

const UserKeyRoute = ({ children }: { children: JSX.Element }) => {
  const userKey = useSelector((state: any) => state.authReducer.userKey);

  return userKey ? (
    children
  ) : (
    <Navigate to={ROUTES.HOW_TO_PARTICIPATE} />
  );
};

export default UserKeyRoute;
