import "./Scanned.scss";
import MainLayout from "../../layouts/MainLayout";



export const Scanned = () => {
  

  return (
    <MainLayout className="register-page">
      <div className="participated-form">
        <p className="scan-text">The QR Code is already scanned </p>
      </div>
      
    </MainLayout>
  );
};
export default Scanned;
