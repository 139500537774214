import { CLEAR_USER_DETAILS, SET_USER_DETAILS, QR_VALID } from "../types";

export interface UserReducerStore {
  name: string;
  state: string;
  mobile: string;
  isValidCode:boolean;
}

const initialState: UserReducerStore = {
  name: "",
  mobile: "",
  state: "",
  isValidCode:false
};

type ActionType =
  | {
      type: "SET_USER_DETAILS";
      payload: {
        name: string;
        state: string;
        mobile: string;
      };
    }
  | {
      type: "CLEAR_USER_DETAILS";
    }
    
    |
    {
      type: "QR_VALID";
      payload: {
        isValidCode:boolean;
      };
    }
    ;

export default function userReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_USER_DETAILS:
      return {
        name: "",
        mobile: "",
        state: "",
      };
      case QR_VALID:
        return {
          isValid:false
        }
    default:
      return state;
  }
}
