import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";

const HowTORedeemPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup  className="howtoredeem-us-popup">
      <div style={{ textAlign: "left"}}>
        <p className="how-to-participate-title">How to Participate</p>
        <p dir="ltr">
          How to Participate in the Program:
        </p>
        <ol>
          <li dir="ltr">
            <p dir="ltr">
              Purchase Eligible Products:
            </p>
          </li>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                Buy 2 Pouches of 1 litre Freedom Refined Sunflower Oil.
              </p>
            </li>
          </ul>
          <li dir="ltr">
            <p dir="ltr">
              Receive a Scratch Card:
            </p>
          </li>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                After your purchase, obtain a scratch card from the retailer.
              </p>
            </li>
          </ul>
          <li dir="ltr">
            <p dir="ltr">
              Scan the QR Code:
            </p>
          </li>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                Use your smartphone to scan the QR code on the scratch card.
              </p>
            </li>
          </ul>
          <li dir="ltr">
            <p dir="ltr">
              Register on the Microsite:
            </p>
          </li>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                Enter your mobile number, name, city, and state.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                Verify your details through an OTP sent to your mobile number.
              </p>
            </li>
          </ul>
          <li dir="ltr">
            <p dir="ltr">
              Confirmation:
            </p>
          </li>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                After registration, you will receive a confirmation message
                thanking you for participating.
              </p>
            </li>
          </ul>
          <li dir="ltr">
            <p dir="ltr">
              Winner Notification:
            </p>
          </li>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                If selected as a winner, you will be notified via WhatsApp.
              </p>
            </li>
          </ul>
          <li dir="ltr">
            <p dir="ltr">
              Participate Multiple Times:
            </p>
          </li>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                You can participate a maximum of 8 times using the same mobile
                number, but you can only win once.
              </p>
            </li>
          </ul>
        </ol>
        <p dir="ltr">
          Important Note:
        </p>
        <ul>
          <li dir="ltr">
            <p dir="ltr">
              This offer is valid only in the states of Andhra Pradesh, Telangana,
              Karnataka, Odisha, and Chhattisgarh.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Ensure that all details entered during registration are accurate to
              avoid disqualification.
            </p>
          </li>
        </ul>
        <p dir="ltr">
          For any questions, contact customer support at 08069261940 or email
          Contactus@gefindia.net.
        </p>

      </div>
    </Popup>
  );
};

export default HowTORedeemPopup;
