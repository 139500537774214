import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ROUTES } from "./lib/consts";
import Register from "./pages/Register/Register";
import Completed from "./pages/Completed/Completed";
import { useEffect } from "react";
import API from "./api";
import { useGlobalLoaderContext } from "./helpers/GlobalLoader";
import store from "./store/store";
import { setUserIdentification } from "./store/actions/authAction";
import { addCaptchaScript, logoutUser } from "./lib/utils";
import { useAuthentication } from "./hooks/useAuthentication";
import PrivateRoute from "./helpers/PrivateRoute";
import TermsPage from "./pages/TermsPage";
import QRComponent from "./QRComponent";
import { useNavigate, useParams } from "react-router-dom";
import NotAllowed from "./pages/Notallowed/NotAllowed";
import UserKeyRoute from "./helpers/UserKeyRoute";
import Scanned from "./pages/Scanned/Scanned";
import Winner from "./pages/Winner/Winner";
import HowToParticipate from "./pages/HowToParticipate";

function App() {
  const { showLoader, hideLoader } = useGlobalLoaderContext();
  const { isLoggedIn, userKey } = useAuthentication();
  const navigate = useNavigate();
  const { pathname } = useLocation();  // Get id if available

  // Call createUser on app initialization if ID is available
  useEffect(() => {
    API.initialize(showLoader, hideLoader);
    
    // If id is available in URL, call createUser
    if (!userKey && !pathname.startsWith("/qr/")) {
      API.createUser().then((response: any) => {
        store.dispatch(setUserIdentification(response));

        // Check login status and handle session
        // if (!response.isLoggedIn && isLoggedIn) {
        //   logoutUser();
        //   toast.info("Your session has been expired");
        // }
      }).catch(() => {
        toast.error("Failed to initialize user");
      });
    }
  }, [userKey, pathname]);

  return (
    <div className="App">
      <Routes>
        <Route path={ROUTES.QR} element={<QRComponent />} />
        <Route path={ROUTES.REGISTER} element={
          <UserKeyRoute>
            <Register />
          </UserKeyRoute>
        } />
        <Route
          path={ROUTES.COMPLETED}
          element={<Completed />}
        />
        <Route path={ROUTES.WINNER} element={<Winner />} />
        <Route path={ROUTES.TERMS_PAGE} element={<TermsPage />} />
        <Route path={ROUTES.HOW_TO_PARTICIPATE} element={<HowToParticipate />} />

        <Route path={ROUTES.NOT_ALLOWED} element={<NotAllowed />} />
        <Route path={ROUTES.SCANNED} element={<Scanned />} />
      </Routes>
      <ToastContainer position="bottom-center" hideProgressBar={true} />
    </div>
  );
}

export default App;
