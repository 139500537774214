import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="howtoredeem-us-popup" >
      <p style={{ textAlign: "left", marginTop: "2rem" }}>
        <strong><p>
          Terms and Conditions: Gold Coins Offer - “Go for Freedom Gold Offer 2024”
        </p></strong>
        <p>
        <strong>1. General Terms:</strong>
        </p>
        <p>
          a) In case of any conflict, these Terms and Conditions will be considered
          final and binding
          in all matters related to this Offer.
        </p>
        <p>
          b) By participating in this Program, participants fully and unconditionally
          agree to and accept these Terms and Conditions, as well as the decisions of Gemini
          Edibles  Fats India Limited (hereinafter referred to as "GEFIL") and
          Pine Labs (hereinafter referred to as "PL"), which are final and binding in all matters
          related to the Program.
        </p>
        <p>
        <strong>2. Offer Details:</strong>
        </p>
        <p>
          a) Program Duration: This offer will begin on 1 st October 2024 and will end
          on 24th November 2024 (both dates inclusive), or till stocks last, whichever is
          earlier (hereinafter the ‘Offer Period’).
        </p>
        <p>
          b) Participation outside the Program Period will not be entertained.
        </p>
        <p>
          c) We retain the right to amend, extend, or terminate the Offer Period
          without prior notice.
        </p>
        <p>
          d) This offer is available to eligible Participants only. We will reach out
          to the lucky Participants on call, SMS or WhatsApp.
        </p>
        <p>
          e) This Offer is valid only on Refined Sunflower Oil, which are marketed in
          the States of Andhra Pradesh, Telangana, Karnataka, Odisha  Chhattisgarh.
        </p>
        <p>
          f) This Offer is valid only on the genuine purchase of 2 Pouches of 1 litre
          Freedom Refined
          Sunflower Oil only against Scratch and Redemption.
        </p>
        <p>
          g) The participant should Scratch the card, Scan the QR Code, fill in the
          details and submit.
        </p>
        <p>
          h) 100 Lucky Winners will have a chance to win and will be rewarded with
          Daily 1 Gram Gold and will be informed through WhatsApp Message and the Winners list will
          be published on www.freedomconsumeroffer.com
        </p>
        <p>
          i) Bumper Lucky Draw: 5 Lucky Winners for 50 Grams Gold Coin  10
          Lucky Winners for 10 Grams Gold Coin will be informed after the scheme ends, through a Bumper
          Lucky Draw Method. Lucky Winners will be informed through WhatsApp Message and the list
          will be published on www.freedomconsumeroffer.com
        </p>
        <p>
          3. Eligibility:
        </p>
        
        <p>
          a) Participant should meet the following criteria to be eligible for this
          Offer.
        </p>
        <p>
          b) Participants must be of the age of 18 years or above.
        </p>
        <p>
          c) This Program is open exclusively to consumers purchasing Freedom Oil
          products.
        </p>
        <p>
          d) Participants must be citizens of India, residing in Andhra Pradesh,
          Telangana, Karnataka, Odisha, and Chhattisgarh. Residents of other countries and non-Indian
          citizens are not
          eligible.
        </p>
        <p>
          e) This Offer or Program is subject to all applicable central, state, and
          local laws and regulations.
        </p>
        <p>
          f) Participants must have purchased goods from the retailers.
        </p>
        <p>
          g) To be eligible to get a gold coin, you must: (a) purchase at least two
          (2) Pouches of 1 Litre Freedom Refined Sunflower Oil only during the offer period.
        </p>
        <p>
          h) In case you do not meet eligibility criteria, you will not be eligible to
          claim the reward.
        </p>
        <p>
        <strong>4. Claim and Delivery:</strong>
        </p>
        <p>
          a) The lucky winner will receive communication through WhatsApp and the list
          of such lucky winners will be published at www.freedomconsumeroffer.com. To claim
          the reward, the lucky winning participant must follow the instructions as
          communicated and (a) provide proof of identity while receiving the reward (b) provide any
          additional information required by us; and (c) pay us the applicable amount of tax to
          be deducted on the reward, in light of applicable provisions of the Income Tax Act,
          1961. If the participant fails to claim the reward as per our communication within five
          (5) business days from the date of the communication, such participant will be deemed to
          have forfeited their claim to the reward and GEFIL will dispose of the reward as
          per its sole
          discretion.
        </p>
        <p>
          b) Once such lucky winning participant claims their reward (as per clause 11
          above), the
        </p>
        <p>
          reward will be delivered to their address registered with us, within ninety
          (90) days from
          the date on which they claim the reward. We wish to ensure that all the
          rewards are delivered to the right address. Thus, we may call the participant on their
          mobile number
          registered with us to verify their address before dispatching the reward. We
          may
          conduct additional due diligence of the details submitted to our
          satisfaction. In such
          cases, the delivery of rewards may take longer than the aforesaid time. We
          will not be {" "}<strong>
          able to deliver and will have to cancel the reward if our courier service
          does not</strong>{" "}
          deliver in your area of residence or fails to deliver the reward even after
          making two
          attempts. You agree not to hold us, our directors, officers, employees, or
          service
          providers, responsible for any delays in delivery of rewards caused due to
          events
          beyond our reasonable control.
        </p>
        <p>
          c) Graphics of the reward in any communication regarding the offer are for
          representation
          purposes only. The actual reward may differ in colour and appearance. In
          circumstances
          beyond our control, we may substitute the reward with an alternative reward
          of equal
          value at our sole discretion. If you earn a reward, you cannot transfer,
          assign, or redeem
          it for cash or other considerations.
        </p>
        <p>
        <strong>5. Other Applicable Terms:</strong>
        </p>
        <p>
          a) This offer, the eligibility criteria, disqualification criteria, validity
          and any other terms
          can be modified, withdrawn, or cancelled, in part or in full, at any time,
          in our sole
          discretion, without any intimation.
        </p>
        <p>
          b) This offer is only valid once per participant and no participant will be
          eligible to win
          more than one gold coin under this offer. This offer cannot be used in
          conjunction with
          any other GEFIL offer or promotion.
        </p>
        <p>
          c) The maximum number of gold coins as referred to in the offer details
          above will be
          distributed as rewards as part of this offer.
        </p>
        <p>
          d) Only genuine purchases will count towards eligibility for this offer. A
          purchase will not
          count towards the eligibility for this offer if: (a) the purchase is not
          valid or appears to
          be suspicious or fraudulent or done with an intent to abuse the process or
          for wrongful
          gain to any participant or wrongful loss to GEFIL; (b) the transaction is
          made towards a purchase of products which are not part of the offer.
        </p>
        <p>
          e) If we find that the reward has been delivered to you incorrectly, we
          reserve the right to claim such an erroneously delivered reward. We also reserve the right to
          withhold or forfeit a reward we believe has been earned fraudulently.
        </p>
        <p>
          f) This offer is being made on a ‘best efforts’ basis and participation in
          this offer is voluntary. By participating in this offer, you agree to release us from
          liability, and harmless us, and any of our affiliates, employees or agents representing or
          related to us and our products/services. This is for all liability arising out of, or in
          connection with this offer, even if caused or contributed to by our negligence.
        </p>
        
        <p>
          g) Our decision as to the reward and any associated computation will be
          final and binding.
        </p>
        
        <p>
          For any disputes about the reward, you must reach out to us within thirty
          (30) days from the last date of the offer.
        </p>
        
        
        <p>
        <strong>6. Additional Terms of Participation, Acceptance and Rewards:</strong>
        </p>
        
        <p>
          a) Your participation in our offers/schemes is deemed to be your acceptance
          of these Terms. Please do not participate in our offers if you do not agree to these
          Terms. These Terms form a binding agreement between you and us.
        </p>
        
        <p>
          b) Please note that all offers are discretionary. To the extent permitted by
          applicable law,
          we reserve the right to modify these Terms or to modify or withdraw an offer
          at any time.
        </p>
        
        <p>
          c) Our employees, and their immediate family members are not eligible to
          participate in our offers.
        </p>
        
        <p>
          d) We reserve the right to disqualify if any participant is found: (a) to
          not meet the offer
          requirements; (b) tampering with the entry process or the operation of an
          offer; (c)

          acting in violation of these Terms; or (d) acting in an unsportsmanlike or
          disruptive manner or with intent to abuse, cause wrongful loss, defraud, threaten or
          harass any person.
        </p>
        
        <p>
          e) You must comply with these Terms to be eligible for participation in an
          offer. In addition, you also must meet the eligibility criteria to participate in.
        </p>
        
        <p>
          f) Offer terms and availability of offers may vary for different
          participants. We may reach out to the eligible participant on call, SMS or WhatsApp. The reward details
          and
          eligibility criteria will be specified in the offer communication. You are
          advised to read the eligibility criteria carefully. Unless otherwise expressly provided, no
          Participant will
          be eligible to participate more than four times i.e. participation limit -
          Each mobile number can participate 8 times in a month during the Program Period but can
          win only once.
          g) Offer rewards shall be in any form, as indicated in the offer above or
          any gift vouchers
          or direct cashback at the discretion of GEFIL. The mode of reward shall be
          as specified in the offer communication. Where a third party’s goods or services are offered
          as
        
          rewards, we are not responsible for their quality, utility, availability,
          limitations or performance. We give no warranty of any kind nor accept any liability in
          connection
        
          with such third-party goods or services distributed as rewards as part of an
          offer.
        </p>
             
        <p>
          h) Your rewards are contingent on adherence to the eligibility criteria. In
          case of any non-
          compliance all your reward will be forfeited.
        </p>
        
        <p>
          i) Our decision about offers and any dispute relating to it will be final
          and binding on all
          participants.
        </p>
        
        <p>
          j) By participating in our offers, you give us and our affiliates permission
          to use your name,
          likeness, image, and appearance (collectively “Image”), as collected or
          received in
          relation to the offer, for our communications, promotions, events, or
          contests. You
          acknowledge that you will not receive any compensation for such use of your
          Image.
          You release us, our affiliates, and their agents and assigns from any claims
          arising out of
          or relating to such use of your Image.
        </p>
        
        <p>
          k) You (and NOT we) are responsible for all taxes, duties, levies or other
          statutory dues and
          charges payable in connection with any rewards you win.
        </p>
        
        <p>
          l) Each offer and these Terms are governed by the laws of India. The
          competent courts at Hyderabad, Telangana will have the exclusive jurisdiction to adjudicate any
          dispute arising out of or relating to these Terms.
        </p>
        
        <p>
          m) Our offers are only valid in India, specifically in the states of Andhra
          Pradesh, Telangana, Karnataka, Odisha  Chhattisgarh. Residents of other states are not
          eligible to participate in the offer. Rewards are determined by lot, draw or any other
          mode of random selection. If you reside in any other state other than the states
          mentioned
          above, you must not participate in such an offer.
        </p>
        
        <p>
          n) We are not responsible for: (a) any error in the operation of the offer,
          including for
          technical, network, telephone, computer, hardware or software malfunctions
          of any

          kind; (b) any inaccurate transmission of, or failure to receive any
          information on account
        
          of technical problems or traffic congestion on the Internet or at any
          website; (c) any
        
          lost, misdirected, late, incomplete, unintelligible or inaccurate
          participation information,
          whether caused by you or by any of the equipment or program associated with
          or
          utilized in the offer, or by any technical or human error while processing
          the offer; (d)
  
          any printing or typographical errors in any materials associated with the
          offer; or (e)

          injury or damage to your or any other computer or mobile resulting from
          downloading
          any materials in connection with the offer.
        </p>
        
        <p>
        <strong>7. Program or Offer Flow:</strong>
        </p>
        
        <p>
          a) Microsite Participation:
        </p>
        
        <p>
           Consumers will scan the QR code, enter their mobile number, name, city,
          and state
       
          on the Program microsite, and validate the information through an OTP sent
          to their
      
          mobile number.
        </p>
        
        <p>
           Participants must accept the Terms  Conditions and confirm their
          WhatsApp-
       
          enabled mobile number.
        </p>
        
        <p>
          b) <strong>Registration:</strong>
        </p>
        
        <p>
           After completing registration, consumers will receive a confirmation
          message
          thanking them for participating. They will be notified if selected as a
          winner.
        </p>
        
        <p>
          c) <strong>One-Time QR Code Use:</strong>
        </p>
        
        <p>
           Each QR code can only be scanned and submitted once. If scanned again, the
          site
          will notify that the code has already been utilized.
        </p>
        
        
        
        
        
        <p>
          d) <strong>Winner Selection  Announcement:</strong>
        </p>
        
        <p>
           100 winners will be selected daily. Winner details, such as Name, Phone
          Number,
          City, and State, will be updated on the Program microsite.
        </p>
        
        <p>
           State-wise the bifurcation of winners will be updated on the microsite.
        </p>
        
        <p>
           Winners will receive a WhatsApp message requesting their address details,
          which
          they must provide within 5 days. Failure to do so will result in
          disqualification.
        </p>
        
        <p>
          e) <strong>Customer Support:</strong>
        </p>
        
        <p>
           A dedicated customer support team will be available in Hindi, English,
          Telugu, and
          Kannada language to address your queries.
        </p>
        
        <p>
           <strong>Customer Support Number:</strong> 08069261940
        </p>
        
        <p>
          f) <strong>WhatsApp Winner Flow:</strong>
        </p>
        
        <p>
           Winners will receive a WhatsApp message asking for their address in a
          step-by-step
        </p>
        
        <p>
          format:
        </p>
        
        <p>
          (1) Street name.
        </p>
        
        <p>
          (2) Area.
        </p>
        
        <p>
          (3) City.
        </p>
        
        <p>
          (4) State.
        </p>
        
        <p>
          (5) PIN code.
        </p>
        
        <p>
          (6) Landmark.
        </p>
        
        <p>
          (7) Confirmation of the address (which cannot be changed later).
        </p>
        
        <p>
          g) <strong>Program Duration  Extension:</strong> The platform will remain live for
          55 days from the official go-live date, with the possibility of extension. The microsite will
          display a message when the program has ended.
        </p>
        
        <p>
          h) <strong>One-Time Winner Rule: </strong>A user can win only once during the promotion i.e.
          the Offer Period.
        </p>
        
        <p>
          i)<strong> Replacement of Winners: </strong>In case a winner's mobile number is not
          available on WhatsApp, a replacement winner will be selected.
        </p>
      </p>
    </Popup>
  );
};

export default TermsPopup;
