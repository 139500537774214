import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../lib/consts";
import React, { useEffect } from "react";
import Popup from "../helpers/Popup";
import "../components/Popups/index.scss";

const TermsPage: React.FC = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Check if the path contains '/how-to-redeem'
  //   if (location.pathname === "/how-to-redeem") {
  //     // Navigate to the HowToRedeemPage
  //     navigate("/how-to-redeem");
  //   }
  // }, [navigate]);
  return (
    <Popup
      title="Terms & Conditions"
      onClose={() => {
        navigate(ROUTES.REGISTER);
      }}
    >

    </Popup>
  );
};

export default TermsPage;
