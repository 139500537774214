import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const ContactUsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="contact-us-popup">
      <p style={{ textAlign: "center" }}>
        <p dir="ltr">For any questions, contact customer support at</p>
        <p dir="ltr">
          Email: <a href="mailto:Contactus@gefindia.net.">Contactus@gefindia.net</a>
        </p>
        <p dir="ltr">or call <a href="tel:08069261940">08069261940</a></p>
      </p>
    </Popup>
  );
};

export default ContactUsPopup;
