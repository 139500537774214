import { PropsWithChildren, useState } from "react";
import { useGlobalModalContext } from "./GlobalModal";
import SideMenu from "../components/SideMenu/SideMenu";
import { useNavigate } from "react-router-dom";




export interface PopupProps extends PropsWithChildren {
  title?: string;
  modalBgClass?: string;
  onClose?: () => void;
  className?: string;
}

export default function Popup(props: PopupProps) {
  const { hideModal } = useGlobalModalContext();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const navigate = useNavigate();



  return (
    <div className={"popup " + props.className}>
      <div className="popup-bg"></div>
      <SideMenu open={showSideMenu} onClose={() => setShowSideMenu(false)} />
      <div className={"popup-modal " + props.modalBgClass}>
        <div className="header">
          {props.title && <p className="title">{props.title}</p>}
          {/* <img
            src={CloseIcon}
            alt="Popup close"
            className="closeBtn"
            onClick={() => {
              hideModal();
              props.onClose && props.onClose();
          navigate(ROUTES.WINNER)

            }}
          /> */}
          <img
            src={`${process.env.REACT_APP_ASSETS_BASE_PATH}/images/menu-icon.png`}
            onClick={() => setShowSideMenu(true)}
            alt="menu"
            className="menu-icon"
          />
          <img
            src={`${process.env.REACT_APP_ASSETS_BASE_PATH}/images/right-logo.png`}
            onClick={() => setShowSideMenu(true)}
            alt="menu"
            className="right-logo"
          />
        </div>
        <img
          src={`${process.env.REACT_APP_ASSETS_BASE_PATH}/images/banner.png`}
          alt="logo"
          className="pop-logo"
        />
        <div className="content">{props.children}</div>
      </div>
    </div>
  );
}
